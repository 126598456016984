@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
}

.debug-grid * {
  box-shadow: 0 0 0 1px rgba(255, 100, 255, 0.5);
}

.wireframe * {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.box {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.material-shadow {
  box-shadow: 0px 4px 8px 0px rgba(33, 33, 33, 0.3);
}

* p {
  color: #63636f;
}

.border-1 {
  border-width: 1px;
}

.outline-helper:focus {
  outline: 1px solid #e3e3e3;
}

.z-focus-1 {
  z-index: 5;
}

.z-focus-2 {
  z-index: 10;
}

.z-focus-1:focus,
.z-focus-2:focus {
  z-index: 25;
}

.markdown-container {
  code {
    color: #512da8;
    border: #512da8 1px solid;
    background-color: #efefff;
    border-radius: 4px;
  }
}

.discord-button-shadow {
  box-shadow: 0px 8px 14px 0px rgba(33, 33, 33, 0.4),
    0px 10px 20px 0px rgba(33, 33, 33, 0.4);
}

.inline-scrollbar {
  overflow-y: scroll;
  // overflow-x: hidden;
}

.inline-scrollbar::-webkit-scrollbar {
  background-color: white;
  width: 14px;
  border-left: 1px solid #c4c4c4;
}

.inline-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c5c5c400;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition: background-color 1s ease;
}

.inline-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #c5c5c4ff;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
}

.inline-scrollbar::-webkit-scrollbar-thumb:vertical {
  height: 20px !important;
  width: 20px;
}

.output-table-context {
  table {
    border-spacing: 0px;
    border-collapse: collapse;
    border: 1px solid transparent;
  }
  td,
  th {
    padding: 0.25em 1em;
    padding-left: 1.5em;
    text-align: right;
    border-right: 1px transparent;
    border-left: 1px transparent;
    font-weight: bold;
  }

  tr td {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  tr:nth-child(even) td {
    background: #f5f5f5;
  }

  th {
    border-color: transparent;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.buttonInit {
  color: #497ce3;
  background: white;
}

.math-inline svg {
  display: inline !important;
}

.monaco-editor .editor-widget {
  display: none !important;
  visibility: hidden !important;
}
